import classNames from 'classnames';
import { useFlags } from 'launchdarkly-react-client-sdk';
import PropTypes from 'prop-types';

import { analyticsTrack } from '@pumpkincare/analytics';
import { WELLNESS_SWIFTER_URL } from '@pumpkincare/config';
import {
  CAT,
  DOG,
  FORMATTED_WELLNESS_PHONE_NUMBER,
  WELLNESS_PHONE_NUMBER_LINK,
} from '@pumpkincare/shared';
import {
  ButtonStyles,
  LegalBody,
  LoaderButton,
  Typography,
} from '@pumpkincare/shared/ui';
import { useGetSwifterToken, WellnessClubBenefitsOverview } from '@pumpkincare/user';

import styles from './pet-wellness-upsell.css';

const BREED_MAP = {
  [DOG]: {
    wellnessClubValue: '$19.95',
  },
  [CAT]: {
    wellnessClubValue: '$14.95',
  },
};

function PetWellnessUpsell({ pet }) {
  const { punks1903WellnessUpsell } = useFlags();

  const { species, id: petId } = pet;
  const { mutateAsync: getSwifterToken, isLoading: loadingSwifterToken } =
    useGetSwifterToken(petId);

  function handleExplorePlansClick() {
    analyticsTrack({
      event: 'Click Wellness Upsell',
      category: 'Wellness',
    });
    getSwifterToken(petId).then(token => {
      let url = `${WELLNESS_SWIFTER_URL}/?user_token=${token}`;
      if (window.location.search) {
        url += `${window.location.search.replace('?', '&')}`;
      }
      window.open(url, '_blank');
    });
  }

  return (
    <div className={styles.root}>
      <div className={styles.content}>
        <div className={styles.exploreSection}>
          <img
            src='/assets/images/iconImages/heart-clifford.png'
            alt=''
            className={styles.topIcon}
          />
          <span className={classNames(Typography.h4, styles.title)}>
            Join the Pumpkin Wellness Club to save hundreds on routine care, and more
          </span>
          <LegalBody className={styles.subTitle}>
            Get cash back and save on routine preventive care your pet needs to stay
            healthy. Access discounts on top pet brands. Join the club today for as
            low as {BREED_MAP[species].wellnessClubValue}/mo!
          </LegalBody>
          {punks1903WellnessUpsell ? (
            <LoaderButton
              className={classNames(ButtonStyles.primary, styles.exploreButton)}
              isLoading={loadingSwifterToken}
              onClick={handleExplorePlansClick}
            >
              Explore Plans
            </LoaderButton>
          ) : (
            <LegalBody isBold className={styles.exploreLink}>
              Call{' '}
              <a
                href={WELLNESS_PHONE_NUMBER_LINK}
                style={{ textDecoration: 'none' }}
              >
                {FORMATTED_WELLNESS_PHONE_NUMBER}
              </a>{' '}
              to explore plans
            </LegalBody>
          )}
        </div>
        <div className={styles.entitlementsSection}>
          <div className={styles.entitlementsBox}>
            <WellnessClubBenefitsOverview species={species} />
          </div>
        </div>
      </div>
    </div>
  );
}

PetWellnessUpsell.defaultProps = {
  pet: {},
};

PetWellnessUpsell.propTypes = {
  pet: PropTypes.shape({
    id: PropTypes.string,
    species: PropTypes.string,
  }),
};

export default PetWellnessUpsell;
