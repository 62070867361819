import { useState } from 'react';
import PropTypes from 'prop-types';
import Store from 'store';

import { GaLink } from '@pumpkincare/analytics/ui';
import {
  ARF_MEOW_PHONE_NUMBER,
  MAINTENANCE_MODAL_HAS_SHOWN_COOKIE,
  PHONE_ARIA_LABEL,
  PHONE_NUMBER_LINK,
} from '@pumpkincare/shared';
import { Modal } from '@pumpkincare/shared/ui';

import styles from './maintenance-modal.css';

function MaintenanceModal({ timeStart, timeEnd }) {
  const maintenanceModalCookie = Store.get(MAINTENANCE_MODAL_HAS_SHOWN_COOKIE);

  const [isOpen, setIsOpen] = useState(!maintenanceModalCookie);

  function handleOnClose(type) {
    if (type === 'icon') {
      Store.set(MAINTENANCE_MODAL_HAS_SHOWN_COOKIE, true);
      setIsOpen(false);
    }
  }

  const schedule =
    timeStart && timeEnd ? (
      <>
        at <b>{timeStart}</b> to <b>{timeEnd}</b>
      </>
    ) : (
      ''
    );

  return isOpen ? (
    <Modal
      onClose={type => handleOnClose(type)}
      classes={{
        container: styles.container,
        content: styles.content,
        modalDialog: styles.modalDialog,
      }}
    >
      <img
        src='/assets/images/maintenance-icon.png'
        aria-hidden='true'
        alt='Seal'
        className={styles.topIcon}
      />

      <h3 className={styles.modalTitle}>Scheduled Maintenance! </h3>

      <span className={styles.textContainer}>
        Pumpkin will be unavailable {schedule} as the team will be performing
        scheduled maintenance at this time. <br />
        We just need some time to spruce things up. <br />
        <b>
          Have questions?
          <br />
          We’re just a paw away.
        </b>
        <GaLink
          href={PHONE_NUMBER_LINK}
          aria-label={PHONE_ARIA_LABEL}
          data-event={'Click Phone Number'}
          data-category={'About Pumpkin'}
          data-label={'Navigation Bar'}
        >
          {ARF_MEOW_PHONE_NUMBER}
        </GaLink>
      </span>
    </Modal>
  ) : null;
}

MaintenanceModal.propTypes = {
  timeEnd: PropTypes.string,
  timeStart: PropTypes.string,
};

export default MaintenanceModal;
