import classNames from 'classnames';
import PropTypes from 'prop-types';

import { formatISODate } from '@pumpkincare/shared';
import { Body1, Typography } from '@pumpkincare/shared/ui';

import styles from './happy-anniversary-page.module.css';

function HappyAnniversaryPage({ pets }) {
  return (
    <div className={styles.container}>
      <div className={styles.imageWrapper}>
        <img
          className={styles.anniversaryImage}
          src='/assets/images/celebration.png'
          alt=''
        />
      </div>
      <div>
        <div className={classNames(Typography.h1, styles.title)}>
          Happy almost Pumpkinversary!
        </div>
        {pets.map(({ pet, renewalPolicyStartDate }) =>
          renewalPolicyStartDate ? (
            <Body1 className={styles.subtext} key={pet.id}>
              {`${pet.name}’s Plan is automatically renewing on `}
              <b>
                {formatISODate(renewalPolicyStartDate, {
                  format: 'MMM D, YYYY',
                })}
              </b>
              {'.'}
            </Body1>
          ) : null
        )}
        <Body1 className={styles.subtext}>
          Let’s review your plan renewal details!
        </Body1>
      </div>
    </div>
  );
}

HappyAnniversaryPage.defaultProps = {
  pets: [],
};

HappyAnniversaryPage.propTypes = {
  pets: PropTypes.array,
};

export default HappyAnniversaryPage;
