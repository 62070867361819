import PropTypes from 'prop-types';

import styles from './onboarding-welcome.css';

function OnboardingWelcome({ onNextClick }) {
  return (
    <div className={styles.root}>
      <h3>OnboardingWelcome</h3>
      <button onClick={onNextClick}>Next</button>
    </div>
  );
}

OnboardingWelcome.propTypes = {
  onNextClick: PropTypes.func.isRequired,
};

export default OnboardingWelcome;
