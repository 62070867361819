import { createRoot } from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Provider } from 'react-redux';
import { Elements } from '@stripe/react-stripe-js';
import { fetchAuthSession, getCurrentUser, signOut } from 'aws-amplify/auth';
import { ConnectedRouter } from 'connected-react-router';
import Store from 'store';

import { configureAmplify, LAUNCH_DARKLEY_KEY } from '@pumpkincare/config';
import {
  BannersProvider,
  BreakpointsProvider,
  captureException,
  IDENTITY_LOCAL_STORAGE_KEY,
  setIsLoggedIn,
} from '@pumpkincare/shared';
import { GlobalStylesInject } from '@pumpkincare/shared/ui';

import { createMemberCenterStore, history } from './state/redux-store';
import App from './view/app';
import { configureApp, initLaunchDarkly, initStripe } from './config';

export default function AppController() {
  const store = createMemberCenterStore();

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        onError: captureException,
        refetchOnWindowFocus: false,
        retry: 0,
      },
    },
  });

  const identityId = Store.get(IDENTITY_LOCAL_STORAGE_KEY);
  const ldUser = {
    ...(identityId ? { key: identityId } : { anonymous: true }),
    country: window.Cypress ? 'e2e' : '',
  };
  const { withLaunchDarkly, ldClient } = initLaunchDarkly(
    LAUNCH_DARKLEY_KEY,
    ldUser
  );
  const EnhancedApp = withLaunchDarkly(App);

  bootstrap();
  authenticateUser();
  render();

  function bootstrap() {
    ldClient.identify(ldUser);

    configureApp();
  }

  function authenticateUser() {
    configureAmplify();

    getCurrentUser()
      .then(() => {
        return fetchAuthSession();
      })
      .then(() => {
        setIsLoggedIn(true);
      })
      .catch(() => {
        setIsLoggedIn(false);
        signOut();
      });
  }

  function render() {
    const container = document.getElementById('root');
    const root = createRoot(container);

    root.render(
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <Elements
            stripe={initStripe()}
            options={{
              fonts: [
                {
                  // load custom font to Stripe iFrame
                  cssSrc: 'https://fonts.googleapis.com/css?family=Nunito+Sans',
                },
              ],
            }}
          >
            <QueryClientProvider client={queryClient}>
              <ReactQueryDevtools initialIsOpen={false} />

              <BannersProvider>
                <BreakpointsProvider>
                  <GlobalStylesInject />
                  <EnhancedApp />
                </BreakpointsProvider>
              </BannersProvider>
            </QueryClientProvider>
          </Elements>
        </ConnectedRouter>
      </Provider>
    );
  }
}
