import {
  dateIsAfter,
  dateIsBefore,
  getCustomerAgreement,
} from '@pumpkincare/shared';
import { downloadPolicy, downloadUpdatedPolicy } from '@pumpkincare/user';

const INACTIVE_STATUSES = ['inactive', 'cancelled', 'expired'];
const ACTIVE_STATUSES = ['active', 'pending'];

export function downloadPolicyCa(policyId) {
  return downloadPolicy(policyId).then(url => {
    window.open(url, '_blank');
  });
}

export function downloadUpdatedPolicyCa(policyUpdatedId, documentId) {
  return downloadUpdatedPolicy(policyUpdatedId, documentId).then(url => {
    window.open(url, '_blank');
  });
}

// 3.1 and 4.0 agreement without summary. Agreement is species-agnostic
export function downloadPlanCa(petSpecies = 'dog', version = 4) {
  const preventCustomerAgreementPath = getCustomerAgreement(petSpecies, version);

  window.open(preventCustomerAgreementPath, '_blank');
}

export function getHasInactivePolicy(item) {
  return INACTIVE_STATUSES.some(status => status === item.status);
}

export function getHasActivePolicy(item) {
  return ACTIVE_STATUSES.some(status => status === item.status);
}

export function getPolicyForDate(policies, dateStr) {
  return policies.find(
    policy =>
      dateIsAfter(dateStr, policy.policy_effective_date) &&
      dateIsBefore(dateStr, policy.policy_end_date)
  );
}
