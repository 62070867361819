import { useState } from 'react';
import classNames from 'classnames';
import { useFlags } from 'launchdarkly-react-client-sdk';
import PropTypes from 'prop-types';

import { analyticsTrack, getAnonymousId } from '@pumpkincare/analytics';
import { QF_URL } from '@pumpkincare/config';
import { getProductCanUpsellPrevent, useProductsByState } from '@pumpkincare/plans';
import { useMutateCreateQuote } from '@pumpkincare/quotes';
import { PET_AGE_OPTIONS } from '@pumpkincare/shared';
import {
  ChevronRight,
  Heart,
  LegalBody,
  LoaderButton,
  Spinner,
  Typography,
} from '@pumpkincare/shared/ui';
import {
  getPetStatusCopy,
  getPlanDates,
  getPlanDocuments,
  getPlanEntitlements,
  getPlanPrice,
  getPolicyConfig,
  getPolicyDates,
  getPolicyDocuments,
  getPolicyPrice,
  getPolicyReimbursementPercentage,
  getPolicyUtilization,
  getUserIsChargedAnnually,
  getUserRatingAddress,
  getUserTransactionFee,
  getWellnessDates,
  getWellnessDocuments,
  getWellnessEntitlements,
  getWellnessPrice,
  PetPlanOverview,
  PetPolicyOverview,
  PetWellnessOverview,
  useUserAddresses,
  useUssr,
} from '@pumpkincare/user';

import { MemberCallout } from '../../../../shared';
import usePetFromRoute from '../../../hooks/use-pet-from-route';
import usePetPolicyConfig from '../../../hooks/use-pet-policy-config';
import RenewalModal from '../../renewal-modal';
import PetWellnessUpsell from '../pet-wellness-upsell';

import styles from './pet-coverage.css';

import petsGroup from './pets-group.png';
import checkMark from './square_check_mark.png';

function formatPetAge(age) {
  if (!age) return '';

  const ageLabel = PET_AGE_OPTIONS.find(option => option.value === age)?.label;

  return ageLabel.replace(/ old/, '').replace(/year/, 'Year');
}

function PetMemberCallout({ onRenewalModalPromptClick }) {
  const { pet } = usePetFromRoute();

  /*
  turn the first space in a breed name into a non-breaking space to help with responsiveness
  this works better than most responsiveness approaches (e.g controlling width of breed)
  */

  const items = [
    { title: 'Breed', copy: pet?.breed_name?.replace(/\s/, '&nbsp;') },
    { title: 'Age', copy: formatPetAge(pet?.age) },
  ].filter(item => item.copy); // Filter out items where copy is falsy

  const { cancelled, declined, renewal, waiting } = getPolicyConfig(pet.policies);

  return (
    <MemberCallout type='pet' items={items} className={styles.callout}>
      {cancelled.isDeceased ? (
        <LegalBody className={styles.center}>
          In loving memory <Heart width={21} height={16} className={styles.heart} />
        </LegalBody>
      ) : null}

      {declined.isDeclined ? (
        <LegalBody>
          Plan cancels on <b>{declined.endDate}</b>
        </LegalBody>
      ) : null}

      {waiting.isWaiting ? (
        <LegalBody>
          {waiting.isModelLaw ? 'Illness ' : ''}Waiting Period until{' '}
          <b>{waiting.startDate}</b>
        </LegalBody>
      ) : null}

      {renewal.isRenewal ? (
        <>
          <LegalBody style={{ paddingTop: '4px' }}>
            Plan auto renews <b>{renewal.renewalDate}</b>
          </LegalBody>
          <button
            className={styles.renewal}
            onClick={() => onRenewalModalPromptClick(true)}
          >
            <LegalBody isBold>
              View Renewal Details <ChevronRight width={8} height={14} />
            </LegalBody>
          </button>
        </>
      ) : null}
    </MemberCallout>
  );
}

PetMemberCallout.propTypes = {
  onRenewalModalPromptClick: PropTypes.func.isRequired,
};

function PetCoverage() {
  const { punks1928WellnessEmployerBenefit } = useFlags();
  const { data: userData } = useUssr();
  const isAnnuallyCharged = getUserIsChargedAnnually(userData);
  const transactionFee = getUserTransactionFee(userData);
  const { pet } = usePetFromRoute();
  const shouldShowWellnessUpsell = !pet.wellness.length && !pet.plans.length;
  const shouldShowInsuranceUpsell =
    punks1928WellnessEmployerBenefit && !pet.policies.length;

  const {
    isLoading: isCreating,
    mutateAsync: createQuote,
    isError: isCreateQuoteError,
  } = useMutateCreateQuote();

  const { data: addressData } = useUserAddresses();
  const ratingData = getUserRatingAddress(addressData);

  const { data: productsByStateData, isFetching: isProductsFetching } =
    useProductsByState(ratingData.state_province);
  const canUpsellPrevent = getProductCanUpsellPrevent(productsByStateData);

  const configPets = usePetPolicyConfig();

  const [isRenewalModalOpen, setIsRenewalModalOpen] = useState(false);

  const hasInsurance = !!pet.policies.length;
  const hasWellness = !!pet.wellness.length;

  const { active } = getPolicyConfig(pet.policies);

  function handleRenewalModalClose() {
    setIsRenewalModalOpen(false);
  }

  function handleOnEnrollButtonClick() {
    const { identityId } = userData;
    const trackingId = getAnonymousId();

    analyticsTrack({
      event: 'Click Insurance Upsell',
      category: 'Insurance',
    });

    createQuote({ identityId, trackingId, petId: pet.id }).then(quoteId => {
      const url = `${QF_URL}/quote?quoteId=${quoteId}`;
      window.open(url, '_self');
    });
  }

  const isOnlyWellnessActive = hasWellness && !active.isActive;

  const showPEPSection =
    !hasWellness &&
    !shouldShowWellnessUpsell &&
    (pet.plans.length || canUpsellPrevent);
  const showPEPSpinner =
    !hasWellness &&
    !shouldShowWellnessUpsell &&
    !(pet.plans.length || canUpsellPrevent) &&
    isProductsFetching;

  return (
    <div className={styles.root}>
      <PetMemberCallout onRenewalModalPromptClick={setIsRenewalModalOpen} />

      <div className={styles.products}>
        {hasInsurance ? (
          /*
          WELLNESS->INSURANCE UPSELL
          remove conditional, always render PetPolicyOverview
          */

          <PetPolicyOverview
            hasInsurance={hasInsurance}
            documents={getPolicyDocuments(pet.policies)}
            utilization={getPolicyUtilization(pet.policies)}
            dateConfig={getPolicyDates(pet.policies)}
            price={getPolicyPrice(pet.policies, isAnnuallyCharged)}
            classes={{
              root: isOnlyWellnessActive ? styles.order2 : styles.order1,
            }}
            petStatus={getPetStatusCopy(pet.policies)}
            reimbursementPercentage={getPolicyReimbursementPercentage(pet.policies)}
          />
        ) : null}

        {showPEPSection ? (
          <PetPlanOverview
            hasPlans={!!pet.plans.length}
            petName={pet.name}
            price={getPlanPrice(pet.plans, isAnnuallyCharged)}
            entitlements={getPlanEntitlements(pet.plans)}
            documents={getPlanDocuments(pet.plans)}
            dateConfig={getPlanDates(pet.plans)}
            petStatus={getPetStatusCopy(pet.plans)}
            classes={{ root: styles.order3 }}
          />
        ) : null}
        {showPEPSpinner ? <Spinner /> : null}

        {hasWellness ? (
          <PetWellnessOverview
            petName={pet.name}
            canUpsellInsurance={!hasInsurance}
            price={getWellnessPrice(
              pet.wellness,
              isAnnuallyCharged,
              punks1928WellnessEmployerBenefit
            )}
            entitlements={getWellnessEntitlements(pet.wellness)}
            documents={getWellnessDocuments(pet.wellness)}
            dateConfig={getWellnessDates(
              pet.wellness,
              'Pumpkin Wellness Club',
              punks1928WellnessEmployerBenefit
            )}
            classes={{
              root: isOnlyWellnessActive ? styles.order1 : styles.order2,
            }}
            petStatus={
              pet.wellness.length && !pet.plans.length
                ? getPetStatusCopy(pet.wellness)
                : ''
            }
          />
        ) : null}
      </div>

      {shouldShowInsuranceUpsell ? (
        <div className={styles.insuranceEnrollment}>
          <div className={styles.content}>
            <div className={styles.copySection}>
              <span className={classNames(Typography.h4, styles.sectionTitle)}>
                Add Pet Insurance for {pet.name}
                <img src={checkMark} alt='' />
              </span>

              <LegalBody className={styles.sectionCopy}>
                You're already saving on wellness care. Now help give your pet the
                best care for accidents & illnesses and get cash back on eligible vet
                bills with a Pumpkin Pet Insurance plan.
              </LegalBody>

              <div className={styles.enrollButton}>
                <LoaderButton
                  onClick={handleOnEnrollButtonClick}
                  color='primary'
                  isLoading={isCreating}
                  classes={{ root: styles.enrollButton }}
                >
                  Enroll Now
                </LoaderButton>
              </div>
            </div>

            <div className={styles.imageSection}>
              <img
                src='/images/illustrations/shapes/circle-courage-pink.svg'
                alt=''
                className={styles.circleIcon}
              />

              <img src={petsGroup} alt='' className={styles.petsImage} />
            </div>
          </div>

          {isCreateQuoteError ? (
            <span className={styles.errorMessage}>
              There was a problem creating your quote. Please try again.
            </span>
          ) : null}
        </div>
      ) : null}

      {shouldShowWellnessUpsell ? <PetWellnessUpsell pet={pet} /> : null}

      {isRenewalModalOpen ? (
        <RenewalModal
          isAnnuallyCharged={isAnnuallyCharged}
          configPets={configPets}
          onClose={handleRenewalModalClose}
          transactionFee={transactionFee}
        />
      ) : null}
    </div>
  );
}

export default PetCoverage;
