import { dateIsToday, formatPolicyDates } from '@pumpkincare/shared';

import { getHasActivePolicy } from './policy-utils';

export function formatPetAge(age) {
  const years = Number(age);

  if (Number.isNaN(years)) {
    return `Under 12 months`;
  } else if (years === 1) {
    return `${years} year`;
  } else {
    return `${years} years`;
  }
}

const numbers = /\d+/;

function getCurrentAndRenewalProducts(pet) {
  return {
    policy: pet.policies?.find(policy => getHasActivePolicy(policy)) || null,
    plan: pet.plans?.find(plan => getHasActivePolicy(plan)) || null,
    renewalPlan: pet.plans?.find(plan => plan.status === 'renew_pending'),
    renewalPolicy:
      pet.policies?.find(policy => policy.status === 'renew_pending') || null,
  };
}

function getRenewalPolicyData(renewalPolicy) {
  const renewalPolicyPrice = renewalPolicy
    ? parseFloat(renewalPolicy.premium)
    : null;

  const formattedRenewalPolicyDates = renewalPolicy
    ? formatPolicyDates(
        renewalPolicy.policy_effective_date,
        renewalPolicy.policy_end_date,
        renewalPolicy.policy_illness_coverage_start_date
      )
    : null;

  const renewalPolicyStartDate = formattedRenewalPolicyDates?.startDate ?? null;
  const renewalPolicyEndDate = formattedRenewalPolicyDates?.endDate ?? null;

  const renewalPolicyAnnualDeductible = renewalPolicy
    ? parseInt(renewalPolicy.deductible.match(numbers))
    : null;

  const renewalPolicyAnnualReimbursement = renewalPolicy
    ? `${100 - parseInt(renewalPolicy.coinsurance.match(numbers))}%`
    : null;

  const renewalPolicyAnnualLimit = renewalPolicy
    ? renewalPolicy.annual_limit.replace('Limit', '')
    : null;

  return {
    renewalPolicyPrice,
    renewalPolicyStartDate,
    renewalPolicyEndDate,
    renewalPolicyAnnualDeductible,
    renewalPolicyAnnualLimit,
    renewalPolicyAnnualReimbursement,
  };
}

function getPolicyData(policy) {
  const isRenewalDay = policy ? dateIsToday(policy.policy_effective_date) : false;

  const formattedPolicyDates = policy
    ? formatPolicyDates(
        policy.policy_effective_date,
        policy.policy_end_date,
        policy.policy_illness_coverage_start_date
      )
    : null;

  const policyStartDate = formattedPolicyDates?.startDate ?? null;
  const policyEndDate = formattedPolicyDates?.endDate ?? null;
  const policyEffectiveDate = formattedPolicyDates?.illnessEffectiveDate ?? null;

  const policyAnnualDeductible = policy
    ? parseInt(policy.deductible.match(numbers))
    : null;

  const policyAnnualReimbursement = policy
    ? `${100 - parseInt(policy.coinsurance.match(numbers))}%`
    : null;

  const policyAnnualLimit = policy ? policy.annual_limit.replace('Limit', '') : null;

  return {
    policyStartDate,
    policyEndDate,
    policyEffectiveDate,
    policyAnnualDeductible,
    policyAnnualLimit,
    policyAnnualReimbursement,
    isRenewalDay,
  };
}

export function transformPetPolicy(pet) {
  if (!pet || !Object.keys(pet).length) return {};

  const { policy, plan, renewalPolicy, renewalPlan } =
    getCurrentAndRenewalProducts(pet);

  const {
    renewalPolicyPrice,
    renewalPolicyStartDate,
    renewalPolicyEndDate,
    renewalPolicyAnnualDeductible,
    renewalPolicyAnnualLimit,
    renewalPolicyAnnualReimbursement,
  } = getRenewalPolicyData(renewalPolicy);

  const {
    policyStartDate,
    policyEndDate,
    policyEffectiveDate,
    policyAnnualDeductible,
    policyAnnualLimit,
    policyAnnualReimbursement,
    isRenewalDay,
  } = getPolicyData(policy);

  const planVersion = plan?.plan?.version_number;
  const renewalPlanPrice = renewalPlan?.plan
    ? parseFloat(renewalPlan.plan.cost)
    : null;

  return {
    renewalPolicyPrice,
    renewalPolicyStartDate,
    renewalPolicyEndDate,
    renewalPlanPrice,
    policyStartDate,
    policyEndDate,
    policyEffectiveDate,
    policyAnnualDeductible,
    policyAnnualReimbursement,
    policyAnnualLimit,
    renewalPolicyAnnualReimbursement,
    renewalPolicyAnnualDeductible,
    renewalPolicyAnnualLimit,
    renewalPlan,
    renewalPolicy,
    policy,
    isRenewalDay,
    planVersion,
  };
}
