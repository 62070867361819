import {
  getPetsWithEmployerWellness,
  getUserIsChargedAnnually,
  getUserLapsedSince,
  getUserPets,
  useUssr,
} from '@pumpkincare/user';

import EnrolledPlansSection from './enrolled-plans-section';
import PurchasedPlansSection from './purchased-plans-section';
import UpcomingPaymentsSection from './upcoming-payments-section';

import styles from './account-billing.css';

function AccountBilling() {
  const { data: userData, isFetching: isUserFetching } = useUssr();
  const isChargedAnnually = getUserIsChargedAnnually(userData);
  const isUserLapsed = !!getUserLapsedSince(userData);
  const pets = getUserPets(userData);
  const petsWithPoliciesOrWellness = pets.filter(
    pet =>
      pet.policies?.length > 0 ||
      (pet.wellness?.length > 0 &&
        pet.wellness.every(wellness => !wellness.group_enrolled))
  );
  const petsWithEmployerWellness = getPetsWithEmployerWellness(pets);
  const hasInsuranceOrWellness = petsWithPoliciesOrWellness.length > 0;
  const hasEmployerWellness = petsWithEmployerWellness.length > 0;

  return (
    <div className={styles.root}>
      {hasInsuranceOrWellness ? (
        <PurchasedPlansSection
          isChargedAnnually={isChargedAnnually}
          isUserLapsed={isUserLapsed}
          hasEmployerWellness={hasEmployerWellness}
        />
      ) : null}
      {hasEmployerWellness ? (
        <EnrolledPlansSection
          enrolledPets={petsWithEmployerWellness}
          hasInsuranceOrWellness={hasInsuranceOrWellness}
        />
      ) : null}

      {hasInsuranceOrWellness ? (
        <UpcomingPaymentsSection
          isUserFetching={isUserFetching}
          pets={petsWithPoliciesOrWellness}
        />
      ) : null}
    </div>
  );
}

export default AccountBilling;
