import classNames from 'classnames';
import PropTypes from 'prop-types';

import {
  CONTACT_EMAIL,
  CONTACT_EMAIL_LINK,
  FORMATTED_PARENTHESES_PHONE_NUMBER,
  PHONE_NUMBER_LINK,
} from '@pumpkincare/shared';
import { Body2, ButtonStyles, Modal } from '@pumpkincare/shared/ui';

// eslint-disable-next-line css-modules/no-unused-class
import modalStyles from '../account-wrapper-modal-shared.css';
import styles from './mailing-address-update-modal.css';

import boxes from './boxes.png';

function MailingAddressUpdateModal({ handleCloseModal, header }) {
  return (
    <Modal
      onClose={handleCloseModal}
      classes={{
        container: modalStyles.modalContainer,
        content: modalStyles.modal,
      }}
      aria-label={header}
    >
      <h3>{header}</h3>
      <h5>{header}</h5>

      <div className={modalStyles.modalBody} style={{ textAlign: 'center' }}>
        <Body2>Congrats on the move!</Body2>
        <Body2>
          Contact us at{' '}
          <a href={PHONE_NUMBER_LINK}>{FORMATTED_PARENTHESES_PHONE_NUMBER}</a> or{' '}
          <a href={CONTACT_EMAIL_LINK}>{CONTACT_EMAIL}</a> to request an address
          change. Remember, the new address on your account must also be the home of
          your pet.
        </Body2>
      </div>

      <img className={styles.modalImg} src={boxes} alt='' />

      <button
        onClick={handleCloseModal}
        className={classNames(styles.addressModalButton, ButtonStyles.secondary)}
      >
        Got it, Thanks!
      </button>
    </Modal>
  );
}

MailingAddressUpdateModal.defaultProps = {
  header: 'Update account address',
};

MailingAddressUpdateModal.propTypes = {
  handleCloseModal: PropTypes.func.isRequired,
  header: PropTypes.string,
};

export default MailingAddressUpdateModal;
