import { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import {
  ButtonStyles,
  FileUpload,
  LegalBody,
  LoaderButton,
  Modal,
} from '@pumpkincare/shared/ui';
import {
  useDeletePetPhoto,
  useMutatePostPetPhoto,
  useMutateUploadToS3,
  useMutateUserPostSignedUrlPetPhoto,
} from '@pumpkincare/user';

import styles from './pet-photo-modal.css';

import trashCanIcon from './trash_can_icon.svg';

function PetPhotoModal({
  onCancel,
  petName = '',
  petId,
  placeHolderImage,
  petPhoto,
  onSave,
}) {
  const [selectedPhoto, setSelectedPhoto] = useState({});
  const [currentRawFile, setCurrentRawFile] = useState(petPhoto);
  const [errors, setErrors] = useState({});

  const { mutateAsync: postSignedUrl, isLoading: loadingSignedUrl } =
    useMutateUserPostSignedUrlPetPhoto();

  const { mutateAsync: uploadToS3, isLoading: loadingUploadS3 } =
    useMutateUploadToS3();

  const { mutateAsync: postPetPhoto, isLoading: loadingPostPhoto } =
    useMutatePostPetPhoto(petId);

  const { mutateAsync: deletePetPhoto, isLoading: loadingDeletePetPhoto } =
    useDeletePetPhoto(petId);

  async function handleUpload(file, rawFile) {
    const signedUrlResponse = await postSignedUrl({ fileName: file.name });
    const signedUrlParams = signedUrlResponse[file.name];
    file.key = signedUrlParams.fields.key;

    const uploadResponse = await uploadToS3({
      file: rawFile,
      signedUrls: signedUrlResponse,
    }).catch(() => {
      setErrors(prevState => ({ ...prevState, uploadError: true }));
      setSelectedPhoto({});
      setCurrentRawFile(null);
    });

    if (uploadResponse) {
      setCurrentRawFile(URL.createObjectURL(rawFile));
      return file;
    }
  }

  const loaderClassName = classNames({
    [styles.loadingCircle]:
      loadingSignedUrl ||
      loadingUploadS3 ||
      loadingPostPhoto ||
      loadingDeletePetPhoto,
  });

  function handleOnChangeImage() {
    setSelectedPhoto({});
    setCurrentRawFile(null);
  }

  async function handleOnSave() {
    try {
      await deletePetPhoto();
    } catch {
      setErrors(prevState => ({ ...prevState, deleteError: true }));
      return;
    }

    try {
      await postPetPhoto({
        file_key: selectedPhoto.key,
        source_file_name: selectedPhoto.name,
      });
      onSave();
    } catch {
      setErrors(prevState => ({ ...prevState, postError: true }));
    }
  }

  async function handleOnRemoveIconClick() {
    try {
      await deletePetPhoto();
      setSelectedPhoto({});
      setCurrentRawFile(null);
    } catch {
      setErrors(prevState => ({ ...prevState, deleteError: true }));
    }
  }

  return (
    <div>
      <Modal onClose={onCancel}>
        <div className={styles.root}>
          <h3 className={styles.modalTitle}> {`${petName}’s Profile Photo`}</h3>
          <div className={styles.imageWrapper}>
            <div className={loaderClassName} />
            {currentRawFile ? (
              <button onClick={handleOnRemoveIconClick} className={styles.trashIcon}>
                <img src={trashCanIcon} alt='' />
              </button>
            ) : null}

            <img
              className={styles.petIcon}
              src={currentRawFile || placeHolderImage}
              alt={currentRawFile ? 'current uploaded image' : 'placeholder image'}
            />
          </div>

          {!currentRawFile ? (
            <FileUpload
              files={selectedPhoto}
              onFileListChange={setSelectedPhoto}
              multiple={false}
              classes={{ root: styles.fileUpload }}
              onUpload={handleUpload}
              variant='buttonUpload'
              maxFileSize={{ raw: 100000000, formatted: '100MB' }}
              acceptedFilesText='.bmp, .png, .jpg, .jpeg, .tif, .tiff'
              onCancel={onCancel}
            />
          ) : (
            <div className={styles.buttonsContainer}>
              {Object.entries(errors).length > 0 ? (
                <LegalBody className={styles.errorText}>
                  Something went wrong. Please try again later or reach out to
                  support
                </LegalBody>
              ) : null}
              <button
                disabled={loadingDeletePetPhoto}
                onClick={handleOnChangeImage}
                className={ButtonStyles.secondary}
              >
                Change image
              </button>

              <LoaderButton
                color={'primary'}
                disabled={loadingDeletePetPhoto || !selectedPhoto.key}
                onClick={handleOnSave}
                isLoading={loadingDeletePetPhoto}
              >
                Save
              </LoaderButton>
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
}

PetPhotoModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  petName: PropTypes.string,
  petId: PropTypes.string.isRequired,
  placeHolderImage: PropTypes.string,
  petPhoto: PropTypes.string,
  onSave: PropTypes.func.isRequired,
};

export default PetPhotoModal;
