import { getActivePlan, parsePlanDetails } from '@pumpkincare/plans';
import {
  getUserIsChargedAnnually,
  getUserPets,
  getUserRatingAddress,
  getUserTransactionFee,
  useUserAddresses,
  useUssr,
} from '@pumpkincare/user';

import { formatPetAge, transformPetPolicy } from '../utils/transform-pet-policy';

export function formatPetPolicyConfig(
  pets,
  isAnnuallyCharged,
  transactionFee,
  ratingState
) {
  return pets
    .filter(item => {
      return item.policies[0]?.policy_effective_date !== null;
    })
    .map(pet => {
      const transformedPetPolicy = transformPetPolicy(pet);
      const {
        policy,
        renewalPlan,
        renewalPolicy,
        renewalPolicyPrice,
        renewalPlanPrice,
      } = transformedPetPolicy;
      const activePlan = getActivePlan(pet.plans);
      const userHasPlan = !!activePlan;
      const isActivePetPolicyAvailable = policy && !!policy.policy_s3_path;
      const isRenewalPetPolicyAvailable =
        renewalPolicy && !!renewalPolicy.policy_s3_path;
      const renewalPlanDescription = renewalPlan?.plan.description;

      const multiplier = isAnnuallyCharged ? 12 : 1;
      const monthlyFee = transactionFee || 0;
      const insurancePrice = (renewalPolicyPrice || 0) * multiplier;
      const preventPrice = (renewalPlanPrice || 0) * multiplier;

      const preventLineItemsFormatted = renewalPlan
        ? parsePlanDetails(renewalPlan.plan, pet.species)
        : [];

      const subtotal = insurancePrice + (preventPrice || 0);
      const total = subtotal + monthlyFee / 100;

      transformedPetPolicy.renewalPolicyPrice = insurancePrice;
      transformedPetPolicy.renewalPlanPrice = preventPrice;
      transformedPetPolicy.renewalSubtotal = subtotal;
      transformedPetPolicy.renewalTotal = total;
      transformedPetPolicy.monthlyFee = monthlyFee;
      const summaryProductsText = `Pumpkin Pet Insurance${
        userHasPlan ? ` + Preventive Essentials` : ``
      }`;

      const summaryInfoParts = [
        pet?.age && `Age: ${formatPetAge(pet.age)}`,
        pet?.breed_name && `Breed: ${pet.breed_name}`,
        `Location: ${ratingState}`,
      ].filter(Boolean); // Filter out falsy values

      const summaryInfoText = summaryInfoParts.join(' | ');

      return {
        pet,
        ...transformedPetPolicy,
        userHasPlan,
        isActivePetPolicyAvailable,
        isRenewalPetPolicyAvailable,
        renewalPlanDescription,
        summaryProductsText,
        summaryInfoText,
        preventLineItemsFormatted,
      };
    });
}

export default function usePetPolicyConfig() {
  const { data: addressData } = useUserAddresses();
  const ratingState = getUserRatingAddress(addressData).state_province;

  const { data: userData } = useUssr();

  const pets = getUserPets(userData);
  const isAnnuallyCharged = getUserIsChargedAnnually(userData);
  const transactionFee = getUserTransactionFee(userData);

  return formatPetPolicyConfig(pets, isAnnuallyCharged, transactionFee, ratingState);
}
